var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("psi-page-title", {
        attrs: {
          title: _vm.page.title,
          icon: _vm.page.icon,
          breadcrumbs: _vm.breadcrumbs
        }
      }),
      _c(
        "v-card",
        { staticClass: "mt-4" },
        [
          _c(
            "v-card-text",
            [
              _c("h4", { staticClass: "mb-2" }, [_vm._v("Student Detail")]),
              _c("v-divider"),
              Object.keys(_vm.currentInstructor).length > 0
                ? _c("apex-bar-chart", {
                    attrs: {
                      average: 422,
                      data: _vm.$_.get(
                        _vm.currentInstructor,
                        "sessions[0].charts.credit_score.series.data",
                        []
                      ),
                      categories: _vm.$_.get(
                        _vm.currentInstructor,
                        "sessions[0].charts.credit_score.categories",
                        []
                      )
                    }
                  })
                : _vm._e(),
              Object.keys(_vm.currentInstructor).length > 0
                ? _c("apex-column-chart", {
                    attrs: {
                      average: 422,
                      data: _vm.$_.get(
                        _vm.currentInstructor,
                        "sessions[0].charts.credit_score.series.data",
                        []
                      ),
                      categories: _vm.$_.get(
                        _vm.currentInstructor,
                        "sessions[0].charts.credit_score.categories",
                        []
                      )
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }