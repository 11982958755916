<template>
    <div>
        <apexchart
            type="bar"
            :height="height"
            :options="chartOptions"
            :series="series"
        ></apexchart>
    </div>
</template>
<script>
import themeMixin from "@/mixins/theme";
export default {
    name: "apex-column-chart",
    components: {},
    mixins: [themeMixin],
    props: {
        title: {
            type: String,
            required: false,
            default: "Series 1",
        },
        height: {
            type: Number,
            default: 400,
            required: false,
        },
        width: {
            type: [Number, String],
            required: false,
            default: "100%",
        },
        categories: {
            type: Array,
            required: false,
            default: () => [],
        },
        data: {
            type: Array,
            required: true,
        },
        average: {
            type: Number,
            required: false,
            default: 0,
        },
        averageLabel: {
            type: String,
            required: false,
            default: "Program Average",
        },
        // Can be vuetify style colors i.e. primary darken-2 or accent lighten-1
        colorPalette: {
            type: String,
            required: false,
            default: "secondary",
        },
        // Fill color for annotation
        fillColor: {
            type: String,
            required: false,
            default: "#000",
        },
        borderColor: {
            type: String,
            required: false,
            default: "#000",
        },
        backgroundColor: {
            type: String,
            required: false,
            default: "alternat1 darken-2",
        },
        labelColor: {
            type: String,
            required: false,
            default: "#000",
        },
    },
    data() {
        return {
            chartOptions: {
                chart: {
                    height: this.height,
                    type: "bar",
                    events: {
                        click: (chart, w, e) => {
                            console.log(chart, w, e);
                        },
                    },
                },
                colors: this.themePalette(this.colorPalette),
                annotations:
                    this.average === 0
                        ? {}
                        : {
                              yaxis: [
                                  {
                                      y: this.average,
                                      fillColor: this.fillColor,
                                      borderColor: this.borderColor,
                                      label: {
                                          position: "left",
                                          textAnchor: "start",
                                          style: {
                                              color: "#fff",
                                              background: this.themeColor(
                                                  this.backgroundColor
                                              ),
                                              fontFamily: "Roboto",
                                              fontSize: "12px",
                                              fontWeight: 700,
                                              padding: {
                                                  left: 15,
                                                  right: 15,
                                                  top: 5,
                                                  bottom: 5,
                                              },
                                          },
                                          text: "Program Average",
                                      },
                                  },
                              ],
                          },
                plotOptions: {
                    bar: {
                        columnWidth: "45%",
                        distributed: true,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                legend: {
                    show: false,
                },
                xaxis: {
                    categories: this.categories,
                    labels: {
                        style: {
                            colors: ["#333"], // this.themeColors(this.colorPalette),
                            fontSize: "12px",
                        },
                    },
                },
            },
            series: [
                {
                    name: this.title,
                    data: this.data,
                },
            ],
        };
    },
};
</script>

<style scoped>
</style>