<template>
    <div>
        <psi-page-title
            :title="page.title"
            :icon="page.icon"
            :breadcrumbs="breadcrumbs"
        ></psi-page-title>
        <!-- Content always starts with a v-card  -->
        <v-card class="mt-4">
            <v-card-text>
                <h4 class="mb-2">Student Detail</h4>
                <v-divider></v-divider>
                <apex-bar-chart
                    v-if="Object.keys(currentInstructor).length > 0"
                    :average="422"
                    :data="
                        $_.get(
                            currentInstructor,
                            'sessions[0].charts.credit_score.series.data',
                            []
                        )
                    "
                    :categories="
                        $_.get(
                            currentInstructor,
                            'sessions[0].charts.credit_score.categories',
                            []
                        )
                    "
                >
                </apex-bar-chart>

                <apex-column-chart
                    v-if="Object.keys(currentInstructor).length > 0"
                    :average="422"
                    :data="
                        $_.get(
                            currentInstructor,
                            'sessions[0].charts.credit_score.series.data',
                            []
                        )
                    "
                    :categories="
                        $_.get(
                            currentInstructor,
                            'sessions[0].charts.credit_score.categories',
                            []
                        )
                    "
                >
                </apex-column-chart>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import ApexBarChart from "@/components/lib/Charts/ApexBarChart";
import ApexColumnChart from "@/components/lib/Charts/ApexColumnChart";
import { mapGetters, mapActions } from "vuex";
export default {
    components: { ApexBarChart, ApexColumnChart },
    name: "program-student",
    data() {
        return {
            page: {
                title: "Student Detail",
                icon: "mdi-school",
            },
            breadcrumbs: [
                {
                    text: "Program Dashboard",
                    disabled: false,
                    to: "#",
                },
                {
                    text: "Student Detail",
                    disabled: true,
                    to: "#",
                },
            ],
        };
    },
    computed: {
        ...mapGetters("Programs", ["currentInstructor"]),
    },
    methods: {
        ...mapActions("Programs", ["getCurrentInstructor"]),
    },
    mounted() {
        this.getCurrentInstructor(66);
    },
};
</script>

<style scoped>
</style>